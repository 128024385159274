import deepClone from './deepClone';

type FauxError = Error | { message: string };

export function updateErrorMessage<CloningError extends FauxError>(
  error: CloningError | unknown,
  messageUpdater: string | ((oldMessage: string | undefined) => string),
): CloningError {
  const newError = deepClone(error as CloningError);
  // TODO: start using cause instead of changing error messages
  // mobile safari won’t let us change the error message
  try {
    newError.message =
      typeof messageUpdater === 'string'
        ? messageUpdater
        : messageUpdater(newError?.message);
  } catch (e) {
    // ignore iOS Safari
  }

  return newError;
}
