import clsx from 'clsx';
import { Fragment, ReactElement } from 'react';
import TableCell, {
  CONTENT_CLASS,
} from 'src/components/common/Table/TableCell';
import Tooltip from 'src/components/common/Tooltip';
import { ColumnComponentDefinition } from './interface';

type TextCellStyle = 'default' | 'error' | 'success' | 'caution';
type CellText = string | number | boolean | null | ReactElement;

interface TextCellProps {
  text: CellText;
  style?: TextCellStyle;
  tooltip?: string;
}

export type TextCellValue = CellText | TextCellProps;

export const isTextCellProps = (value: TextCellValue): value is TextCellProps =>
  value?.['text'];

export interface Props {
  column?: ColumnComponentDefinition;
  cell?: TextCellValue;
}

function TextCell({ cell = '', column }: Props) {
  const style: TextCellStyle = isTextCellProps(cell)
    ? cell.style ?? 'default'
    : 'default';

  return (
    <TableCell
      column={column}
      className={clsx([
        'the-table__cell--text',
        `the-table__cell--style-${style}`,
        { 'the-table__cell--allow-word-break': column?.allowWordBreak },
        { 'the-table__cell--prevent-line-wrap': column?.preventLineWrap },
      ])}
    >
      {isTextCellProps(cell) ? (
        <Tooltip content={cell.tooltip}>
          <span className={CONTENT_CLASS}>
            {['string', 'object'].includes(typeof cell.text)
              ? cell.text
              : String(cell.text)}
          </span>
        </Tooltip>
      ) : (
        <span
          className={clsx([
            CONTENT_CLASS,
            { [`${CONTENT_CLASS}--multiline`]: column?.showMultiline },
          ])}
        >
          {column?.showMultiline && Array.isArray(cell) ? (
            cell.map((text, index) => <div key={index}>{text}</div>)
          ) : column?.revealSpaces ? (
            <span>
              {String(cell)
                .split(' ')
                .flatMap((str, i) => [
                  <span key={i * 2} className="the-table__cell-text-space">
                    {' '}
                  </span>,
                  <Fragment key={i * 2 + 1}>{str}</Fragment>,
                ])
                .slice(1)}
            </span>
          ) : (
            String(cell)
          )}
        </span>
      )}
    </TableCell>
  );
}

export default TextCell;
