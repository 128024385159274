import { ShortDurationUnit, durationInMilliseconds } from './constants';

export function inMilliseconds(
  duration: number,
  durationUnit: ShortDurationUnit,
) {
  return (
    duration *
    (durationInMilliseconds[durationUnit] ??
      durationInMilliseconds[`${durationUnit}s`])
  );
}
