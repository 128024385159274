import clsx from 'clsx';
import './LogoLoader.scss';

interface Props {
  isLoading?: boolean;
  className?: string;
}

function LogoLoader({ isLoading = true, className }: Props) {
  if (!isLoading) {
    return null;
  }

  return (
    <i
      className={clsx(['logo-loader', className])}
      role="status"
      aria-label="loading"
    />
  );
}

export default LogoLoader;
