import noop from 'lodash/noop';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { AlertLevel, isBenignAlertLevel } from 'src/interface/command-center';
import { RequireAtLeastOne, RequiredBy } from 'src/interface/utility';
import { v4 as uuid } from 'uuid';

interface MessageCreateBase {
  type: AlertLevel;
  overridable?: boolean;
}
interface MessageCreateFull extends MessageCreateBase {
  title?: string;
  description?: ReactNode | ReactNode[];
}
type MessageCreate = RequireAtLeastOne<
  MessageCreateFull,
  'title' | 'description'
>;
export type Message = RequiredBy<MessageCreate, 'overridable'> & { id: string };

/** @deprecated */
type LEGACY_MessageCreate = MessageCreateBase & {
  text: ReactNode;
};

const createMessage = ({
  type = 'info',
  title,
  description,
  overridable = isBenignAlertLevel(type),
}: MessageCreate): Message => ({
  id: uuid(),
  type,
  title,
  description,
  overridable,
});

type MessagesState = {
  messages: Message[];
  showMessage: (message: MessageCreate) => string;
  /** @deprecated */
  LEGACY_showMessage: (message: LEGACY_MessageCreate) => string;
  dismissMessage: (id: string) => void;
};

const defaultState: MessagesState = {
  messages: [],
  showMessage: (newMessage: MessageCreate) => createMessage(newMessage).id,
  /** @deprecated */
  LEGACY_showMessage: (newMessage: LEGACY_MessageCreate) =>
    createMessage({
      overridable: newMessage.overridable ?? newMessage.type !== 'error',
      // need to fall back to `null` due to how RequireAtLeastOne works
      description: newMessage.text ?? null,
      type: newMessage.type,
    }).id,
  dismissMessage: noop,
};

const MessagesContext = createContext<MessagesState>(defaultState);

type Props = {
  children: ReactNode;
};

export function MessagesProvider({ children }: Props) {
  const [messages, setMessages] = useState<Message[]>([]);

  const dismissMessage = useCallback((id: string) => {
    setMessages((currentMessages) =>
      currentMessages.filter((message) => message.id !== id),
    );
  }, []);

  const showMessage = useCallback((newMessage: MessageCreate) => {
    const message = createMessage({
      overridable: newMessage.type !== 'error',
      ...newMessage,
    });
    setMessages((currentMessages) => {
      return [
        ...currentMessages.filter(({ overridable }) => !overridable),
        message,
      ];
    });
    return message.id;
  }, []);

  /** @deprecated */
  const LEGACY_showMessage = useCallback((newMessage: LEGACY_MessageCreate) => {
    const message = createMessage({
      overridable: newMessage.type !== 'error',
      // need to fall back to `null` due to how RequireAtLeastOne works
      description: newMessage.text ?? null,
      type: newMessage.type,
    });
    setMessages((currentMessages) => {
      return [
        ...currentMessages.filter(({ overridable }) => !overridable),
        message,
      ];
    });
    return message.id;
  }, []);

  const providerValue: MessagesState = useMemo(
    () => ({
      messages,
      showMessage,
      LEGACY_showMessage,
      dismissMessage,
    }),
    [messages, dismissMessage, LEGACY_showMessage, showMessage],
  );

  return (
    <MessagesContext.Provider value={providerValue}>
      {children}
    </MessagesContext.Provider>
  );
}

export const useMessages = () => {
  const context = useContext(MessagesContext);

  return context;
};
