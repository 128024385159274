import { useSyncExternalStore } from 'react';

let hidden: string;
let visibilityChangeEvent: string;
if (typeof document.hidden !== 'undefined') {
  hidden = 'hidden';
  visibilityChangeEvent = 'visibilitychange';
} else if (typeof document['msHidden'] !== 'undefined') {
  hidden = 'msHidden';
  visibilityChangeEvent = 'msvisibilitychange';
} else if (typeof document['webkitHidden'] !== 'undefined') {
  hidden = 'webkitHidden';
  visibilityChangeEvent = 'webkitvisibilitychange';
} else {
  hidden = '';
  visibilityChangeEvent = '';
}

let windowFocused = false;

window.addEventListener('focus', () => {
  windowFocused = true;
});

window.addEventListener('blur', () => {
  windowFocused = false;
});

export function useWindowInnerWidth() {
  return useSyncExternalStore(
    (listener) => {
      window.addEventListener('resize', listener);

      return () => {
        window.removeEventListener('resize', listener);
      };
    },
    () => window.innerWidth,
  );
}

export function useWindowIsActive() {
  return useSyncExternalStore(
    (listener) => {
      document.addEventListener(visibilityChangeEvent, listener);
      window.addEventListener('focus', listener);
      window.addEventListener('blur', listener);

      return () => {
        document.removeEventListener(visibilityChangeEvent, listener);
        window.removeEventListener('focus', listener);
        window.removeEventListener('blur', listener);
      };
    },
    () => windowFocused || !document[hidden],
  );
}
