import { onlineManager } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import createStore from 'src/components/FleetMonitoring/create-store';

// onlineManager doesn’t have unsubscribe,
//   and onlineManager.listeners is marked as protected,
//   so implement our own outside of react-land
type Listener = (newState: boolean) => void;
const listeners: Set<Listener> = new Set();
let isOnline = onlineManager.isOnline();
onlineManager.subscribe((newState) => {
  isOnline = newState;
  listeners.forEach((listener) => listener(newState));
});

// onlineManager doesn’t work in Safari, so we manually check requests
export const useFailedRequestCountStore = createStore({
  failedRequestCount: 0,
});

export const useHasInternetConnectivity = () => {
  const { failedRequestCount } = useFailedRequestCountStore();
  const [hasInternetConnectivity, setHasInternetConnectivity] =
    useState(isOnline);

  useEffect(function syncConnectivityState() {
    const handleConnectivityChange = (isOnline: boolean) => {
      setHasInternetConnectivity(isOnline);
    };

    listeners.add(handleConnectivityChange);

    return () => {
      listeners.delete(handleConnectivityChange);
    };
  }, []);

  return hasInternetConnectivity && failedRequestCount < 5;
};
