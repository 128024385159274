import {
  CarComponents,
  PrepositionTrip,
  RideTrip,
  Trip,
  LegacyCarComponents,
  State,
  SummaryState,
  TaskEvent,
  TimelineSectionType,
} from '@motional-cc/fe/interface/api/api-concierge';
import { PartnerName } from '@motional-cc/fe/interface/api/api-server';
import {
  Environment,
  Job,
  JobRequest,
  JobRequestBody,
  JobSubtype,
  JobType,
  MaintenanceJob,
  PropName,
  RideshareJob,
  RoadType,
} from '@motional-cc/fe/interface/api/kamaji';
import {
  Model as CarModelName,
  GEN1Vehicle,
  GEN2Vehicle,
  GEN3Vehicle,
  Gen2Platform,
  GenericPackage,
  ModernModel as ModernCarModelName,
  OneTouchStepStatus,
  Pkg,
  Region,
  Vehicle as RegistrarVehicle,
  TestPlatform,
} from '@motional-cc/fe/interface/api/registrar';
import { ProfileNotification } from '@motional-cc/fe/interface/api/user-profile-service';
import t from '@motional-cc/fe/tools/translate';
import { QueryStatus } from '@tanstack/react-query';
import { Scope } from 'src/auth/scopes';
import { Immutable, RequiredKeys } from 'src/interface/utility';
import assertArrayExhaustsType from 'src/tools/assertArrayExhaustsType';
import { isNullish } from 'src/tools/types';

export type Theme = 'LIGHT' | 'DARK';
export type ThemeOption = Theme | 'AUTO';

export const vehicleStates = assertArrayExhaustsType<State>()([
  'Unavailable',
  'Available',
  'WaitingForOnboardResponse',
  'WaitingForDispatchProceed',
  'EnrouteToGoal',
  'ArrivedAtGoal',
]);

export const vehicleSummaryStates = assertArrayExhaustsType<SummaryState>()([
  'UNKNOWN',
  'ENROUTE_TO_PICKUP',
  'ARRIVED_AT_PICKUP',
  'ENROUTE_TO_DROPOFF',
  'ARRIVED_AT_DROPOFF',
  'PREPOSITION',
  'AWAITING_DISPATCH',
  'PARTIALLY_ACTIVE',
  'UNAVAILABLE',
  'INACTIVE',
]);

type CarMode = 'Unknown' | 'Autonomous' | 'Manual';
export const CAR_MODES = assertArrayExhaustsType<CarMode>()([
  'Unknown',
  'Autonomous',
  'Manual',
]);

export type OperatorRole = 'driver' | 'driverSupport';

export interface LonLat {
  lon: number;
  lat: number;
}
export type GeoPoint = Partial<LonLat>;

export interface LngLat {
  lng: number;
  lat: number;
}

// Vehicles are ordered as most recent first
export const MODERN_CAR_MODEL_NAMES =
  assertArrayExhaustsType<ModernCarModelName>()([
    'Ioniq',
    'BMW',
    'Pacifica',
    'Eval',
  ]);
export const CAR_MODEL_NAMES = assertArrayExhaustsType<CarModelName>()([
  'Ioniq',
  'BMW',
  'Pacifica',
  'Eval',
  'Audi',
  'Zoe',
]);

export const REGIONS = assertArrayExhaustsType<Region>()([
  'singapore',
  'boston',
  'pittsburgh',
  'las_vegas',
  'santa_monica',
  'san_diego',
  'austin',
]);

export type Position2d = [number, number];
export type LineString2d = Position2d[];
export type LineString2dArray = LineString2d[];
export type LineString2dMatrix = LineString2d | LineString2dArray;

// locations for centering the map
export const REGION_COORDS: Readonly<{
  [key in Region]: Readonly<Position2d>;
}> = {
  boston: [42.344028, -71.028831],
  singapore: [1.298373, 103.786542],
  pittsburgh: [40.499518, -79.869974],
  las_vegas: [36.1223414, -115.1177555],
  santa_monica: [34.019480613747795, -118.49141485440221],
  san_diego: [32.715736, -117.161087],
  austin: [30.262855634031023, -97.7433355897665],
} as const;

export interface UserProfile {
  id?: string;
  name?: string;
  email?: string;
  phone?: string;
  notifications?: ProfileNotification;
  token_id?: string;
  default_location?: Region;
  image?: string;
  scope?: Scope[];
  theme?: ThemeOption;
  use_imperial?: boolean;
  fleet_id?: string;
}

type ErrorsList = {
  loc: (string | number)[];
  msg: string;
  type: string;
  [meta: string]: string | (string | number)[];
}[];

export class ApiError extends TypeError {
  status: number;
  code?: string;
  errors?: ErrorsList;

  get name() {
    return 'ApiError';
  }

  constructor(
    status: number,
    message: string,
    code?: string,
    errors?: ErrorsList,
  ) {
    super(message);

    this.status = status;
    this.code = code;
    this.errors = errors;
  }
}

export type ErrorResponse =
  | undefined
  | null
  | string
  | ApiError
  | { error: string }
  | { error: ApiError };

// TODO: Clean this up when responses are consistent 🙏
export type PaginatedResponseNoMeta<Result> = {
  result: Result[];
  next_cursor?: string | null;
  total?: number;
};
export type PaginatedResponseWithMeta<Result> = {
  result: Result[];
  meta: {
    next_cursor: string | null;
    total?: number;
  };
};
export type PaginatedResponse<Result> =
  | PaginatedResponseNoMeta<Result>
  | PaginatedResponseWithMeta<Result>;

export type FieldSpacing = 'none' | 'compact' | 'comfortable';

export const GENERIC_FORMAT = 'generic_package' as const;
export type Gen2BundleSupportedPlatform = Gen2Platform | TestPlatform;
export const isGenericPackage = (pkg: Pkg): pkg is GenericPackage =>
  pkg.target_format === GENERIC_FORMAT;

export const GEN2_PLATFORMS = assertArrayExhaustsType<Gen2Platform>()([
  'GEN2_TCar_B1',
  'GEN2_TCar_B1+',
  'GEN2_AVProto',
  'GEN2_AVProto_V2',
  'GEN2_AVPilot',
  'GEN2_AVPilot_V2',
  'GEN2_AVProd',
]);

export const GEN3_PLATFORMS = assertArrayExhaustsType<TestPlatform>()([
  'GEN3_Ampere',
]);

export const GEN2_BUNDLE_SUPPORTED_PLATFORMS =
  assertArrayExhaustsType<Gen2BundleSupportedPlatform>()([
    ...GEN2_PLATFORMS,
    ...GEN3_PLATFORMS,
  ]);

export type NotificationMethod = keyof ProfileNotification;

// export const isLegacyVehicle = (
//   vehicle: RegistrarVehicle,
// ): vehicle is LegacyVehicle => vehicle.platform === 'BMW';

export const isGEN1Vehicle = (
  vehicle: RegistrarVehicle,
): vehicle is GEN1Vehicle => vehicle.platform?.startsWith('GEN1');

export const isGEN2Vehicle = (
  vehicle: Pick<RegistrarVehicle, 'platform'>,
): vehicle is GEN2Vehicle => vehicle.platform?.startsWith('GEN2');

export const isGEN3Vehicle = (
  vehicle: RegistrarVehicle,
): vehicle is GEN3Vehicle => vehicle.platform?.startsWith('GEN3');

export type DataLoadStatus = Lowercase<QueryStatus>;

export const isIncompleteStatus = (
  status: DataLoadStatus,
): status is 'pending' => ['pending'].includes(status);

export const ROAD_TYPES = assertArrayExhaustsType<RoadType>()([
  'test track',
  'public road',
  'mix',
]);

export const ENVIRONMENTS = assertArrayExhaustsType<Environment>()([
  'day',
  'night',
  'precipitation',
  'cloudy',
]);

export const JOB_TYPES = assertArrayExhaustsType<JobType>()([
  'rideshare',
  'maintenance',
  'dev test',
  'public road evaluation',
  'manual data collection',
  'mapping',
  'vehicle integration',
  'demo',
  'training',
]);

export const JOB_SUBTYPES: {
  [jobType in JobType | 'other']: Immutable<JobSubtype[]>;
} = {
  rideshare: ['lyft', 'via', 'uber eats'],
  maintenance: ['vehicle intake', 'vehicle outtake'],
  'dev test': ['daily master', 'specific build'],
  'public road evaluation': ['mileage accumulation'],
  'manual data collection': ['machine learning training', 'resimulation'],
  mapping: [
    'quality assurance',
    'data collection',
    'manual validation',
    'auto validation',
    'scouting mission',
  ],
  'vehicle integration': [
    'dbw update',
    'sensor calibration',
    'sensor validation',
    'software update',
    'component update',
    'troubleshooting',
  ],
  demo: [
    'investor',
    'government official',
    'golden rider',
    'press',
    'internal',
    'photography session',
    'dry run',
  ],
  training: ['new hire', 'recertification'],
  other: assertArrayExhaustsType<JobSubtype>()([
    'lyft',
    'via',
    'uber eats',
    'vehicle intake',
    'vehicle outtake',
    'daily master',
    'specific build',
    'mileage accumulation',
    'machine learning training',
    'resimulation',
    'quality assurance',
    'data collection',
    'manual validation',
    'auto validation',
    'scouting mission',
    'dbw update',
    'sensor calibration',
    'sensor validation',
    'software update',
    'component update',
    'troubleshooting',
    'investor',
    'government official',
    'golden rider',
    'press',
    'internal',
    'photography session',
    'dry run',
    'new hire',
    'recertification',
  ]),
} as const;

const REQUIRED_RIDESHARE_PROPS = assertArrayExhaustsType<
  RequiredKeys<RideshareJob>
>()(['region', 'type', 'subtype', 'starts_at', 'ends_at', 'vehicles']);
export const isRideshareJob = (
  jobRequest: Partial<JobRequestBody>,
): jobRequest is RideshareJob => {
  if (jobRequest.type !== 'rideshare') {
    return false;
  }
  for (const prop of REQUIRED_RIDESHARE_PROPS) {
    if (!(prop in jobRequest) || jobRequest[prop] === undefined) {
      return false;
    }
  }
  return true;
};

const REQUIRED_MAINTENENCE_PROPS = assertArrayExhaustsType<
  RequiredKeys<MaintenanceJob>
>()([
  'region',
  'type',
  'subtype',
  'starts_at',
  'ends_at',
  'vehicles',
  'notes',
  'requestor_must_be_present',
  'ticket',
]);

export const isMaintenanceJob = (
  jobRequest: Partial<JobRequestBody>,
): jobRequest is MaintenanceJob => {
  if (jobRequest.type !== 'maintenance') {
    return false;
  }
  for (const prop of REQUIRED_MAINTENENCE_PROPS) {
    if (!(prop in jobRequest) || jobRequest[prop] === undefined) {
      return false;
    }
  }
  return true;
};

export type FullJobBody = Exclude<
  JobRequestBody,
  RideshareJob | MaintenanceJob
>;
const REQUIRED_OTHER_PROPS = assertArrayExhaustsType<
  RequiredKeys<FullJobBody>
>()(['region', 'type', 'subtype', 'starts_at', 'ends_at', 'vehicles', 'notes']);
export const isFullJob = (
  jobRequest: Partial<JobRequestBody>,
): jobRequest is FullJobBody => {
  if (
    !jobRequest.type ||
    !jobRequest.subtype ||
    jobRequest.type === 'rideshare' ||
    jobRequest.type === 'maintenance'
  ) {
    return false;
  }
  for (const prop of REQUIRED_OTHER_PROPS) {
    if (!(prop in jobRequest) || jobRequest[prop] === undefined) {
      return false;
    }
  }
  return (
    jobRequest.subtype === 'other' ||
    JOB_SUBTYPES[jobRequest.type].includes(jobRequest.subtype)
  );
};

export const PROP_NAMES = assertArrayExhaustsType<PropName>()([
  'mannequin',
  'pedestrian',
  'bicycle',
  'large vehicle',
  'traffic light',
  'stroller',
  'cone',
]);

export const isJob = (job: JobRequest | Job): job is Job =>
  'job_request_id' in job && 'vehicle' in job;

export const isModernCarComponents = (
  carComponents: CarComponents | LegacyCarComponents,
): carComponents is CarComponents =>
  isNullish(
    (carComponents as LegacyCarComponents).nuCarClient ||
      (carComponents as LegacyCarComponents).nuCarStateManager ||
      (carComponents as LegacyCarComponents).nuCarCamVideoStreamer,
  );

export type StringBoolean = `${boolean}`;

export type AlertLevel =
  | 'success' // all good
  | 'caution' // this is usually fine, but could cause edge cases in some scenarios
  | 'warning' // there is loss of funcionality, but they can be worked around
  | 'error' // something is broken, only proceed if you really know what you’re doing
  | 'critical' // something is really wrong, don’t attempt to proceed
  | 'info'; // not really an alert, but maybe interesting

type BenignAlertLevel = AlertLevel & ('success' | 'info');
const BENIGN_ALERT_LEVELS: Immutable<BenignAlertLevel[]> =
  assertArrayExhaustsType<BenignAlertLevel>()(['success', 'info']);
export const isBenignAlertLevel = (
  alertLevel: AlertLevel,
): alertLevel is BenignAlertLevel =>
  BENIGN_ALERT_LEVELS.includes(alertLevel as BenignAlertLevel);

export const SAFE_FALLBACK_PARTNER_NAME: PartnerName = 'uber_fake';

export const TIMELINE_SECTION_TYPES =
  assertArrayExhaustsType<TimelineSectionType>()(['rva', 'ride']);

const ticketRegEx = /(?<ticketId>[A-Z]+-\d+)/;
export const getTicketFromLink = (ticketUrl?: string) =>
  ticketUrl?.match(ticketRegEx)?.groups?.ticketId;

export const ONE_TOUCH_STEP_STATUSES =
  assertArrayExhaustsType<OneTouchStepStatus>()([
    'pass',
    'fail',
    'error',
    'in_progress',
    'awaiting',
    'unknown',
    'timeout',
  ]);

export const FAILED_ONE_TOUCH_STEP_STATUSES: readonly OneTouchStepStatus[] = [
  'error',
  'fail',
  'timeout',
];

export const COMPLETED_ONE_TOUCH_STEP_STATUSES: readonly OneTouchStepStatus[] =
  ['pass', ...FAILED_ONE_TOUCH_STEP_STATUSES];

export const INACTIVE_ONE_TOUCH_STEP_STATUSES: readonly OneTouchStepStatus[] = [
  'awaiting',
  ...COMPLETED_ONE_TOUCH_STEP_STATUSES,
];

const AUTO_MODE_PREFIXES = ['ASD_', 'AD_'];
export const getDriveModeAbbreviation = (mode?: string) => {
  if (!mode) {
    return 'U';
  }

  const knownModeAbbreviation = t(`driveModeIcon.${mode}`);
  if (knownModeAbbreviation) {
    return knownModeAbbreviation;
  }

  if (AUTO_MODE_PREFIXES.some((prefix) => mode.startsWith(prefix))) {
    return 'A';
  }

  return 'U';
};

export const isPreposition = (
  trip: Trip | null | undefined,
): trip is PrepositionTrip => trip?.tripType === 'preposition';

export const isRide = (trip: Trip | null | undefined): trip is RideTrip =>
  trip?.tripType === 'ride';

export const isSameTaskEvent = (task1?: TaskEvent, task2?: TaskEvent) =>
  !!(task1 && task2) &&
  task1.name === task2.name &&
  task1.goalId === task2.goalId;
